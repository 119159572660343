<template>
  <div id="mian" class="container">
    <ContainerList
      ref="container"
      :page-size="filterData.pageSize"
      :page-current.sync="filterData.pageNo"
      :page-count="totalNum"
      @page-change="changePageNo"
      @page-size-change="changePageSize"
    >
      <div slot="header">
        <div class="con_from">
          <div class="lineP">
            <div class="item">
              <i>分账方</i>
              <Input
                v-model="filterData.keyword"
                suffix="ios-search"
                size="large"
                maxlength="128"
                placeholder="分账方名称/编号"
                style="width: calc(100% - 75px - 10px)"
              />
            </div>
            <div class="item">
              <i>分账方属性</i>
              <Select
                v-model="filterData.allocMchType"
                size="large"
                style="width: calc(100% - 75px - 10px)"
              >
                <Option
                  v-for="item in filteredOptions"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}</Option
                >
              </Select>
            </div>
            <div class="itembtn">
              <Button
                class="searchBt"
                @click="getDataByFilter"
                style="margin-left: 20px"
                >查询</Button
              >
            </div>
          </div>
        </div>
      </div>
      <Table
        row-key
        class="table"
        :columns="tableColumns"
        :data="tableData"
        :loading="isLoading"
      >
        <template slot="allocMchId" slot-scope="{ row }">
          {{ row.mchId != null ? row.mchId : row.allocMchId }}
        </template>
        <template slot="allocMchType" slot-scope="{ row }">
          {{ row.allocMchType === "PLATALLOC" ? "平台分账方" : "服务商分账方" }}
        </template>
        <template slot="settleAmount" slot-scope="{ row }">
          {{ (row.settleAmount / 100) | formatMoney }}
        </template>
        <template slot="action" slot-scope="{ row, index }">
          <div class="actionBtn">
            <button
              v-auth="'BASIC:BALANCE:LIST/SEARCH'"
              class="lianjie"
              @click="getDetail(row, index)"
            >
              查询
            </button>
            <button
              v-if="activateStatus !== 'FROZEN' && isUniformTreatment"
              v-auth="'BASIC:BALANCE:LIST/TRANSFER'"
              class="lianjie"
              @click="togoTransfer(row)"
            >
              转账
            </button>
            <button
              v-if="activateStatus !== 'FROZEN' && isUniformTreatment"
              v-auth="'BASIC:BALANCE:LIST/SETTLEMENT'"
              class="lianjie"
              @click="togoSettle(row)"
            >
              结算
            </button>
          </div>
        </template>
      </Table>
    </ContainerList>
  </div>
</template>

<script>
import { getMerchants, getMerchantsDetail } from "@/api/basic/balance.js";
import ContainerList from "@/components/ContainerList/index";
import tableMixin from "@/mixins/tableMixin";
import { mapState, mapMutations } from "vuex";
export default {
  components: { ContainerList },
  mixins: [tableMixin],
  data() {
    return {
      isUniformTreatment: Number(
        JSON.parse(sessionStorage.getItem("userInfo")).isUniformTreatment
      ), //资金统一处理状态 0关 1开
      filterData: {
        pageNo: 1,
        pageSize: 10,
        keyword: "",
        allocMchType: -1,
      },
      totalNum: 0,
      tableColumns: [
        { title: "分账方名称", key: "allocMchName", align: "center" },
        {
          title: "分账方编号",
          key: "allocMchId",
          align: "center",
          slot: "allocMchId",
        },
        {
          title: "分账方属性",
          key: "allocMchType",
          align: "center",
          slot: "allocMchType",
        },
        {
          title: "可用余额(元)",
          key: "settleAmount",
          align: "center",
          slot: "settleAmount",
        },
        { title: "操作", key: "action", align: "center", slot: "action" },
      ],
      tableData: [],
      allocMchTypeOptions: [
        { label: "全部", value: -1 },
        { label: "平台分账方", value: "PLATALLOC" },
        { label: "服务商分账方", value: "AGENTALLOC" },
      ],
      tableApi: getMerchants,
      tableKey: "merchants", // 后端返回的列表字段名
      isFilterKey: true, // 是否开启参数转换
      emptyValue: -1,
      activateStatus: "", //冻结状态 FROZEN 不显示
    };
  },
  computed: {
    ...mapState({
      zhlbQueryDetail: "trading_zhlb",
    }),
    // 资金统一处理为否时，分账方属性仅为平台分账方
    filteredOptions() {
      return this.isUniformTreatment
        ? this.allocMchTypeOptions
        : this.allocMchTypeOptions.filter((item) => item.value != "AGENTALLOC");
    },
  },
  created() {
    this.activateStatus = JSON.parse(
      sessionStorage.getItem("userInfo")
    ).activateStatus;
    if (this.zhlbQueryDetail) {
      this.filterData = this.zhlbQueryDetail.filterData;
      // this.filterData.allocMchType = this.filterData.allocMchType == '' ? '-1' : this.filterData.allocMchType;
    }
    this.getDataByFilter();
  },
  methods: {
    ...mapMutations({
      setZhlbQuery: "trading_setZhlb",
    }),
    // 请求前钩子
    beforeRequest(params) {
      // params.allocMchType = params.allocMchType == -1 ? "" : params.allocMchType;
      return params;
    },
    afterResponse(list, data) {
      this.totalNum = data.totalNum || 0;
      return list;
    },
    // 查询 ( 操作-查询  )
    getDetail(row, index) {
      getMerchantsDetail(row.thirdAccountId).then((res) => {
        if (res) {
          let data = res.resultData || {};
          row.settleAmount = data.settleAmount;
          this.$set(this.tableData, index, row);
          this.$Message.success("查询成功");
        }
      });
    },
    //转账
    togoTransfer(row) {
      getMerchantsDetail(row.thirdAccountId).then((res) => {
        if (res) {
          const arr = res.resultData;
          arr.thirdAccountId = row.thirdAccountId;
          arr.mchId = row.mchId;
          this.$router.push({
            name: "/balanceList/ban_transfer",
            query: arr,
          });
        }
      });
    },
    //结算
    togoSettle(row) {
      getMerchantsDetail(row.thirdAccountId).then((res) => {
        if (res) {
          const arr = res.resultData;
          arr.thirdAccountId = row.thirdAccountId;
          this.$router.push({
            name: "/balanceList/ban_settlement",
            query: arr,
          });
        }
      });
    },
  },
  beforeDestroy() {
    let data = {
      filterData: this.filterData,
    };
    this.setZhlbQuery(data);
  },
};
</script>

<style lang="less" scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.actionBtn {
  display: flex;
  justify-content: center;
}
.con_from .lineP .item > i {
  width: 75px;
  text-align: right;
  margin-right: 10px;
}
.container {
  overflow: hidden;
  background: #fff;
  .table {
    min-height: 700px;
  }
}
</style>
